import React from "react";
import logo from "../logo.png";
import "./Hero.css";

const Hero = () => {
  return (
    <>
      <main className="App-main">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Welcome to White Dolffins.</p>
        <a
          className="App-link"
          href="https://www.whitedolffins.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Official Website
        </a>
      </main>
    </>
  );
};

export default Hero;
