// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-footer {
  background-color: #282c34;
  min-height: 10vh;
  color: aliceblue;
  text-align: center;
  text-decoration: none;
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".App-footer {\n  background-color: #282c34;\n  min-height: 10vh;\n  color: aliceblue;\n  text-align: center;\n  text-decoration: none;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
