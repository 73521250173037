import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header className="App-header">
        <div>
          <Link className="App-title" to="/">
            White Dolffins
          </Link>
        </div>
      </header>
    </>
  );
};

export default Header;
