// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Reset */
body {
  background-color: #f0f0f0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,UAAU;AACV;EACE,yBAAyB;EACzB,UAAU;EACV,SAAS;EACT,sBAAsB;AACxB","sourcesContent":["/* Reset */\nbody {\n  background-color: #f0f0f0;\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
