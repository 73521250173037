// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-header {
  background-color: #282c34;
  min-height: 5vh;
  font-size: calc(10px + 2vmin);
  color: aliceblue;
  border: solid white;
  align-items: center;
  padding-left: 1vw;
  padding-top: 1vw;
}
.App-title {
  font-size: calc(10px + 2vmin);
  font-weight: 3rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-decoration: none;
  color: aliceblue;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,6BAA6B;EAC7B,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".App-header {\n  background-color: #282c34;\n  min-height: 5vh;\n  font-size: calc(10px + 2vmin);\n  color: aliceblue;\n  border: solid white;\n  align-items: center;\n  padding-left: 1vw;\n  padding-top: 1vw;\n}\n.App-title {\n  font-size: calc(10px + 2vmin);\n  font-weight: 3rem;\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  text-decoration: none;\n  color: aliceblue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
