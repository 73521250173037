// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-main {
  background-color: #282c34;
  min-height: 85vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
  display: block;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}
`, "",{"version":3,"sources":["webpack://./src/components/Hero.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".App-main {\n  background-color: #282c34;\n  min-height: 85vh;\n  padding-top: 2vh;\n  padding-bottom: 2vh;\n  display: block;\n}\n\n.App-logo {\n  height: 10vmin;\n  pointer-events: none;\n}\n\n.App-link {\n  color: #61dafb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
